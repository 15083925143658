// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/bg.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".container[data-v-38cf4874] {\n  width: 100%;\n  height: 100%;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: 100% 100%;\n  position: fixed;\n  overflow-y: auto;\n}\n.container .none[data-v-38cf4874] {\n    text-align: center;\n    padding-top: 1rem;\n}\n.container .title[data-v-38cf4874] {\n    margin: .3rem;\n    font-size: .24rem;\n}\n.container .title img[data-v-38cf4874] {\n      width: .24rem;\n      height: .24rem;\n      float: left;\n      margin-right: .1rem;\n}\n.container .list[data-v-38cf4874] {\n    margin: .3rem;\n    background: #fff;\n    box-shadow: 0px -1px 9px 0px rgba(111, 210, 255, 0.09);\n    border-radius: .4rem;\n    padding: .3rem;\n    font-size: .24rem;\n}\n.container .list .list-top[data-v-38cf4874] {\n      padding: .1rem 0 .3rem 0;\n      border-bottom: 1px dotted #ccc;\n}\n.container .list .list-bot[data-v-38cf4874] {\n      line-height: .6rem;\n      margin-top: .2rem;\n}\n.container .list .list-bot .top[data-v-38cf4874] {\n        display: flex;\n        flex-direction: row;\n        justify-content: space-between;\n}\n.container .list .list-bot .top div[data-v-38cf4874] {\n          width: 50%;\n}\n.container .list .list-bot .bottom[data-v-38cf4874] {\n        display: flex;\n        justify-content: space-between;\n}\n.container .list .list-bot .bottom[data-v-38cf4874] :last-child {\n          color: #0068FF;\n}\n.btn a[data-v-38cf4874] {\n  width: 100%;\n  height: .88rem;\n  line-height: .88rem;\n  text-align: center;\n  background: #0068FF;\n  color: #fff;\n  font-size: .26rem;\n  position: fixed;\n  left: 0;\n  bottom: 0;\n}\n.record[data-v-38cf4874] {\n  padding: .4rem;\n  font-size: .26rem;\n}\n.record .record-list[data-v-38cf4874] {\n    display: flex;\n    line-height: .4rem;\n    margin-bottom: .2rem;\n}\n", ""]);
// Exports
module.exports = exports;
