var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "van-pull-refresh",
        {
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.isLoading,
            callback: function($$v) {
              _vm.isLoading = $$v
            },
            expression: "isLoading"
          }
        },
        _vm._l(_vm.list, function(ite) {
          return _c(
            "div",
            [
              _c("div", { staticClass: "title" }, [
                _c("img", {
                  attrs: { src: require("../../assets/images/yh.png") }
                }),
                _vm._v(_vm._s(ite.name))
              ]),
              _vm._l(ite.caseList, function(item) {
                return _c("div", { staticClass: "list" }, [
                  _c("div", { staticClass: "list-top" }, [
                    _vm._v(" 姓名：" + _vm._s(item.name) + " "),
                    item.thirdOrderStatus
                      ? _c("span", { staticStyle: { float: "right" } }, [
                          _vm._v("保单状态:"),
                          _c(
                            "i",
                            {
                              staticStyle: {
                                color: "#FF7200",
                                "font-style": "normal"
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.getThirdOrderStatus(item.thirdOrderStatus)
                                )
                              )
                            ]
                          )
                        ])
                      : _vm._e()
                  ]),
                  _c("div", { staticClass: "list-bot" }, [
                    _c("div", { staticClass: "top" }, [
                      _c("div", { staticStyle: { width: "50%" } }, [
                        _vm._v(
                          "预核保进度：" + _vm._s(_vm.getStatus(item.status))
                        )
                      ]),
                      item.result
                        ? _c("div", [
                            _vm._v(
                              "预核保结果：" +
                                _vm._s(_vm.getResult(item.result))
                            )
                          ])
                        : _vm._e(),
                      item.submitTime
                        ? _c(
                            "div",
                            {
                              staticStyle: { width: "20%" },
                              on: {
                                click: function($event) {
                                  return _vm.getAuditLogList(item.id)
                                }
                              }
                            },
                            [
                              _c("img", {
                                staticStyle: {
                                  width: "11px",
                                  height: "12px",
                                  float: "right",
                                  "margin-top": ".15rem"
                                },
                                attrs: {
                                  src: require("../../assets/images/chakan.png")
                                }
                              })
                            ]
                          )
                        : _vm._e()
                    ]),
                    _c("div", { staticClass: "bottom" }, [
                      item.submitTime
                        ? _c("div", [
                            _vm._v("提交时间：" + _vm._s(item.submitTime))
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          on: {
                            click: function($event) {
                              return _vm.jump(item.status, item.id)
                            }
                          }
                        },
                        [_vm._v("详情查看")]
                      )
                    ])
                  ])
                ])
              })
            ],
            2
          )
        }),
        0
      ),
      _vm.list.length == 0
        ? _c("div", { staticClass: "none" }, [_vm._v("暂无相关数据～")])
        : _vm._e(),
      _c(
        "van-dialog",
        {
          attrs: { confirmButtonText: "关闭", confirmButtonColor: "#2c3e50" },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _vm.recordList.length < 8
            ? _c(
                "div",
                { staticClass: "record" },
                _vm._l(_vm.recordList, function(item, index) {
                  return _c("div", { staticClass: "record-list" }, [
                    _c(
                      "div",
                      { staticStyle: { "margin-right": ".15rem" } },
                      [
                        index != _vm.recordList.length - 1
                          ? _c("van-icon", {
                              attrs: {
                                name: "circle",
                                size: "10",
                                color: "#2c3e50"
                              }
                            })
                          : _c("van-icon", {
                              attrs: {
                                name: "circle",
                                size: "10",
                                color: "#0068FF"
                              }
                            })
                      ],
                      1
                    ),
                    _c("div", [
                      _c("p", [_vm._v(_vm._s(item.createTime))]),
                      _c("p", [_vm._v(_vm._s(item.result))])
                    ])
                  ])
                }),
                0
              )
            : _c(
                "div",
                {
                  staticClass: "record",
                  staticStyle: { height: "370px", "overflow-y": "auto" }
                },
                _vm._l(_vm.recordList, function(item, index) {
                  return _c("div", { staticClass: "record-list" }, [
                    _c(
                      "div",
                      { staticStyle: { "margin-right": ".15rem" } },
                      [
                        index != _vm.recordList.length - 1
                          ? _c("van-icon", {
                              attrs: {
                                name: "circle",
                                size: "10",
                                color: "#2c3e50"
                              }
                            })
                          : _c("van-icon", {
                              attrs: {
                                name: "circle",
                                size: "10",
                                color: "#0068FF"
                              }
                            })
                      ],
                      1
                    ),
                    _c("div", [
                      _c("p", [_vm._v(_vm._s(item.createTime))]),
                      _c("p", [_vm._v(_vm._s(item.result))])
                    ])
                  ])
                }),
                0
              )
        ]
      ),
      _c("div", { staticStyle: { width: "100%", height: "1rem" } }),
      _c(
        "div",
        { staticClass: "btn" },
        [
          _c("router-link", { attrs: { to: "/caseUpload" } }, [
            _vm._v("新增预核保")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }