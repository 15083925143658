<template>
  <div class="container">
<!--    <goback :msg='msg'></goback>-->
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <div  v-for="ite in list" >
        <div class="title"><img src="../../assets/images/yh.png">{{ite.name}}</div>
        <div class="list" v-for="item in ite.caseList">
            <div class="list-top">
              姓名：{{item.name}}
              <span style="float: right;" v-if="item.thirdOrderStatus">保单状态:<i style="color: #FF7200;font-style:normal">{{getThirdOrderStatus(item.thirdOrderStatus)}}</i></span>
            </div>
            <div class="list-bot">
              <div class="top">
                <div style="width: 50%">预核保进度：{{getStatus(item.status)}}</div>
                <div v-if="item.result">预核保结果：{{getResult(item.result)}}</div>
                <div  v-if="item.submitTime"  style="width: 20%" @click="getAuditLogList(item.id)" >
                  <img src="../../assets/images/chakan.png" style="width: 11px; height: 12px; float: right; margin-top: .15rem">
                </div>
              </div>
              <div class="bottom">
                <div v-if="item.submitTime">提交时间：{{item.submitTime}}</div>
                <div @click="jump(item.status,item.id)">详情查看</div>
              </div>
            </div>
        </div>
      </div>
    </van-pull-refresh>
    <div v-if="list.length == 0" class="none">暂无相关数据～</div>
    <van-dialog v-model="show" confirmButtonText="关闭" confirmButtonColor="#2c3e50">
      <div class="record" v-if="recordList.length < 8">
        <div class="record-list" v-for="(item,index) in recordList">
          <div style="margin-right: .15rem">
            <van-icon v-if="index != recordList.length-1" name="circle" size="10"color="#2c3e50" />
            <van-icon v-else name="circle" size="10" color="#0068FF" />
          </div>
          <div>
            <p>{{item.createTime}}</p>
            <p>{{item.result}}</p>
          </div>
        </div>
      </div>
      <div class="record" v-else style="height: 370px;  overflow-y: auto;">
        <div class="record-list" v-for="(item,index) in recordList">
          <div style="margin-right: .15rem">
            <van-icon v-if="index != recordList.length-1" name="circle" size="10"color="#2c3e50" />
            <van-icon v-else name="circle" size="10" color="#0068FF" />
          </div>
          <div>
            <p>{{item.createTime}}</p>
            <p>{{item.result}}</p>
          </div>
        </div>
      </div>
    </van-dialog>
    <div style="width: 100%; height: 1rem"></div>
    <div class="btn"><router-link to='/caseUpload'>新增预核保</router-link></div>
  </div>
</template>

<script>
import {caseList, enumList, operationLogList} from '@/api'
import Vue from 'vue'
import goback from '../goBack/index.vue'
import {Toast,PullRefresh} from 'vant';
Vue.use(PullRefresh);
export default {
  name: 'caseList',
  data() {
    return {
      msg:"我的预核保",
      list:[],
      initList:{},
      isLoading: false,
      fullPath:"",
      show:false,
      recordList:[]
    }
  },
   components: {
     goback
   },

  created() {
    this.getList();
    if(sessionStorage.getItem('init')){
      this.initList = JSON.parse(sessionStorage.getItem('init'))
    }else {
      this.getInit()
    }
  },

  methods: {
    //获取枚举信息
    getInit(){
      enumList().then((res) => {
        let obj = {};
        res.map((e) => {
          obj[e.enumType] = e.enumList;
        });
        this.initList = obj
        sessionStorage.setItem('init', JSON.stringify(obj));
      //  this.$store.commit('SET_INIT', obj)
      })
    },
    getList() {
      const toast = Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
      });
      caseList().then((res) => {
        toast.clear()
        this.list = res
        if(res.length == 0 && this.fullPath=='/login'){
          this.$router.push({path:'/caseUpload'});
        }
      })
    },
    getStatus(status){
        return this.initList.caseStatus.filter(item => item.code===status )[0].name
    },
    getResult(status){
        return this.initList.resultStatus.filter(item => item.code===status )[0].name
    },
    getThirdOrderStatus(status){
      return this.initList.caseThirdOrderStatus.filter(item => item.code===status )[0].name
    },
    ///跳转
    jump(type,id){
      if(type == '01'){
       this.$router.push({path:'/caseUpload',query:{'caseId':id}});
      }else if(type == '02'){
        this.$router.push({path:'/caseDetails',query:{'caseId':id,'type':'01'}})
      }else if(type == '04'){
        this.$router.push({path:'/caseDetails',query:{'caseId':id,'status':type}})
      }else if(type == '05'){
        this.$router.push({path:'/caseDetails',query:{'caseId':id,'supplement':'01'}})
      }else{
        this.$router.push({path:'/caseDetails',query:{'caseId':id}});
      }
    },
    onRefresh() {
      setTimeout(() => {
        Toast('刷新成功');
        this.isLoading = false;
        this.getList()
      }, 1000);
    },
    getAuditLogList(id){
      operationLogList(id).then((res) => {
         this.recordList = res
         this.show = true
      })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if(from.fullPath == '/login'){
        vm.fullPath = from.fullPath
      }
    });
  },
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  background: url('../../assets/images/bg.jpg');
  background-size:100% 100% ;
  position: fixed;
  overflow-y:auto;
  .none{
    text-align: center;
    padding-top: 1rem;
  }
  .title{
    margin: .3rem;
    font-size: .24rem;
    img{
      width: .24rem;
      height: .24rem;
      float: left;
      margin-right: .1rem;
    }
  }
  .list{
    margin: .3rem;
    background: #fff;
    box-shadow: 0px -1px 9px 0px rgba(111, 210, 255, 0.09);
    border-radius: .4rem;
    padding: .3rem;
    font-size: .24rem;
    .list-top{
      padding: .1rem 0 .3rem 0;
      border-bottom: 1px dotted #ccc;
    }
    .list-bot{
       line-height: .6rem;
       margin-top: .2rem;
      .top{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        div{
          width: 50%;
        }
      }
      .bottom{
        display: flex;
        justify-content: space-between;
        :last-child{
          color: #0068FF;
        }
      }
    }
  }
}
.btn a{
  width: 100%;
// height: 100px;
  height: .88rem;
  line-height:.88rem;
  text-align: center;
  background: #0068FF;
  color: #fff;
  font-size: .26rem;
  position: fixed;
  left: 0;
  bottom: 0;
}
.record{
 padding:.4rem;
  font-size: .26rem;
  .record-list{
    display: flex;
    line-height: .4rem;
    margin-bottom: .2rem;
  }
}
</style>
